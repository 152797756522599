const sentryConfig = {
  dsn: 'https://22d1828a92214253bf9997b4e2480e48@o941469.ingest.sentry.io/5890278',
  project: 'vastilo',
  org: 'vastilo',
  token: 'eb9defdedd744b19a391558ac3450b095dd653b4c112438b89204608c4eac7dd',
  url: 'https://sentry.io/'
}

const initOptions = {
  dsn: sentryConfig.dsn,
  environment: process.env.STAGE
}

exports.initOptions = initOptions

exports.sentryConfig = sentryConfig
