import * as Sentry from '@sentry/nextjs'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

import { initOptions } from './sentry.config'

Sentry.init({
  ...initOptions,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampler: () => (process.env.NODE_ENV === 'production' ? 1 : 0.2)
})
